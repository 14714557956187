import React from 'react';

import Authorize from 'features/auth/components/Auth/Auth';
import {get, capitalCase} from 'utils/helpers';
import SaveDateStyled from '../../../generic/components/SaveDateStyled/SaveDateStyled';
import {
    ListingNameLink,
    Stacked,
    StatusStyled,
} from '../../../generic/components/Table/styles';

import {AccountNameLink} from './styles';

const columns = [
    {
        flex: 0.75,
        HeaderComponent: () => <span>Date Created</span>,
        ChildComponent: (props) =>
            props.subscription.created_at ? (
                <SaveDateStyled dateOnly date={props.subscription.created_at} />
            ) : null,
    },
    {
        flex: 1.25,
        HeaderComponent: () => <span>Listing</span>,
        ChildComponent: (props) => (
            <div>
                <Authorize permissions={['listing-view-all']}>
                    <ListingNameLink href={`/listing/${props.subscription.listing_id}/vip`}>
                        {props.subscription.listing_name}
                    </ListingNameLink>
                </Authorize>
                <Authorize permissions={['account-view-all']}>
                    <AccountNameLink href={`/account/${props.subscription.account_id}`}>
                        {props.subscription.account_name}
                    </AccountNameLink>
                </Authorize>
            </div>
        ),
    },
    {
        flex: 1,
        HeaderComponent: () => <span>Package</span>,
        ChildComponent: (props) => (
            <Stacked>
                <div>{get(props, 'subscription.package.region.name')}</div>
            </Stacked>
        ),
    },
    {
        flex: 0.5,
        HeaderComponent: () => <span>Status</span>,
        ChildComponent: (props) => (
            <StatusStyled status={props.subscription.status}>
                {capitalCase(props.subscription.status)}
            </StatusStyled>
        ),
    },
];

export default columns;
