import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';

import {searchSubscriptions} from 'features/vip/actions';
import {selectVipRegions} from 'features/vip/selectors';
import VipList from 'features/vip/components/VipList/VipList';
import {queryStringConverter} from 'utils/helpers';

const initialFilters = {
    page: 1,
    count: 10,
    site: null,
    region_name: null,
    status: null,
};

let VipListContainer = (props) => {
    const [activeModalId, setActiveModalId] = useState(null);
    const [error, setError] = useState(null);
    const [filters, setFilters] = useState({...initialFilters});

    useEffect(() => {
        updateFilters().catch((e) => {
            setError(e && e.message ? e.message : 'Cannot get subscriptions');
        });
    }, []);

    const updateFilters = (id, value) => {
        let updatedFilters = filters;

        if (id && value) {
            updatedFilters[id] = updatedFilters[id] === value ? null : value;
        }

        setActiveModalId(null);
        setFilters(updatedFilters);
        return updateSearch(queryStringConverter(updatedFilters, true));
    };

    const toggleModal = (id) => {
        setActiveModalId((prevActiveModalId) => {
            return id === prevActiveModalId ? null : id;
        });
    };

    const updateSearch = (query) => {
        return props.searchSubscriptions(query);
    };

    const resetFilters = () => {
        setFilters({...initialFilters});
        updateSearch(queryStringConverter({...initialFilters}, true));
    };

    const handleLoadPage = (page) => {
        const updatedFilters = queryStringConverter({...filters, page}, true);
        updateSearch(updatedFilters);
    };

    return (
        <VipList
            {...props}
            activeModalId={activeModalId}
            error={error}
            filters={filters}
            toggleModal={toggleModal}
            resetFilters={resetFilters}
            updateFilters={updateFilters}
            handleLoadPage={handleLoadPage}
        />
    );
};

VipListContainer = connect(
    (state) => ({
        regions: selectVipRegions(state),
        subscriptions: (state?.vip?.subscriptionResults || []).filter(
            (element) => typeof element === 'object',
        ),
        isFetching: state.vip.isFetching,
        pagination: state.vip.subscriptionPagination,
    }),
    {
        searchSubscriptions,
    },
)(VipListContainer);

export default VipListContainer;
