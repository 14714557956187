import React from 'react';

import {formatNumber} from 'utils/helpers';
import {
    ArrowContainer,
    ArrowSvgLeft,
    ArrowSvgRight,
    Container,
    First,
    Last,
    Showing,
} from './styles';

const Pagination = (props) => {
    const total = parseInt(props.total);
    const count = parseInt(props.count);
    const page = parseInt(props.page) || 1;

    const pages = Math.ceil(total / count);

    const paginationStart = page * count - count + 1;
    const paginationEnd = page * count > total ? total : page * count;

    const showFirst = pages > 4;

    const skipFive = showFirst && page + 5 <= pages;

    let showing = `${formatNumber(paginationStart)}-${formatNumber(
        paginationEnd,
    )} of ${formatNumber(total)}`;

    if (paginationStart === paginationEnd && paginationStart === total) {
        showing = paginationStart + ' of ' + paginationStart;
    }

    if (total === 0) {
        return (
            <Container
                $css={props.$css}
                className={props.className}
                $isFetching={props.isFetching}>
                <Showing>0 Results</Showing>
            </Container>
        );
    }

    return (
        <Container
            $css={props.$css}
            className={props.className}
            $isFetching={props.isFetching}>
            {paginationStart !== 1 && (
                <ArrowContainer
                    $isFetching={props.isFetching}
                    onClick={() => !props.isFetching && props.handleLoadPage(page - 1)}>
                    <ArrowSvgLeft />
                </ArrowContainer>
            )}
            {showFirst && paginationStart !== 1 && (
                <First onClick={() => !props.isFetching && props.handleLoadPage(1)}>
                    First
                </First>
            )}
            <Showing>{showing}</Showing>
            {skipFive && (
                <Last onClick={() => !props.isFetching && props.handleLoadPage(page + 5)}>
                    Skip 5
                </Last>
            )}
            {paginationEnd !== total && (
                <ArrowContainer
                    $isFetching={props.isFetching}
                    onClick={() => !props.isFetching && props.handleLoadPage(page + 1)}>
                    <ArrowSvgRight />
                </ArrowContainer>
            )}
        </Container>
    );
};

export default Pagination;
