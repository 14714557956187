import {BOX_SHADOW, COLORS, MAX_WIDTH, bp} from 'core/styles';
import {getListingType, get} from 'utils/helpers';
import styled, {css} from 'styled-components';
const tileWidth = '310px';

export let container = (props) => {
    return {
        backgroundColor:
            get(props, 'user.type') === 'external' ? COLORS.grayLight : COLORS.gray,
    };
};
export let OuterContainer = styled.div`
    background-color: ${(props) =>
        props?.user?.type === 'external' ? COLORS.grayLight : COLORS.gray};
`;

export let InnerContainer = styled.div`
    padding: 30px;
    max-width: ${MAX_WIDTH};
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    position: relative;
    align-items: start;

    ${bp('xl')} {
        flex-flow: row wrap;
    }
`;

export let listing = {
    width: tileWidth,
    display: 'flex',
    flexFlow: 'column nowrap',
    marginBottom: '15px',
};

export let ListingContainer = styled.div`
    width: ${tileWidth};
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 15px;
`;

export let tile = (props) => {
    return {
        padding: 0,
        margin: 0,
        boxShadow: get(props, 'user.type') === 'external' ? BOX_SHADOW : '0 2px 5px #000',
    };
};
export let ListingTileCss = css`
    padding: 0px;
    margin: 0px;
    boxshadow: ${(props) =>
        get(props, 'user.type') === 'external' ? BOX_SHADOW : '0 2px 5px #000'};
`;

export let top = {
    textAlign: 'right',
    marginBottom: '10px',
};

export let ListingTop = styled.div`
    text-align: right;
    margin-bottom: 10px;
`;

export let rotationType = {
    color: COLORS.grayFont,
    fontWeight: 900,
    display: 'inline-block',
    textTransform: 'uppercase',
    fontSize: '10px',
};

export let type = {
    color: COLORS.grayFont,
    fontWeight: 900,
    display: 'inline-block',
    textTransform: 'uppercase',
    fontSize: '10px',
    marginRight: '15px',
};

export let ListingType = styled.div`
    color: ${COLORS.grayFont};
    font-weight: 900;
    display: inline-block;
    text-transform: uppercase;
    fontsize: 10px;
    margin-right: 15px;
`;

export let ListingIndicator = styled.div`
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: 8px;
    vertical-align: top;
    position: relative;
    border-radius: 50%;
    background-color: ${(props) =>
        ['vip', 'promoted', 'standard'].includes(getListingType(props.listing))
            ? COLORS.success
            : COLORS.warning};
`;

export let InnerAnalytics = styled.div`
    padding: 15px;
    min-height: 127px;
`;

export let name = {
    cursor: 'pointer',
    color: COLORS.primary,
    fontWeight: 900,
    fontSize: '14px',
    textDecoration: 'underline',
    marginBottom: '10px',
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
};

export let title = {
    fontWeight: 'bold',
    fontSize: '11px',
    color: COLORS.grayFont,
    textTransform: 'uppercase',
    opacity: 0.5,
};

export let photo = {
    cursor: 'pointer',
    height: '166px',
    backgroundColor: COLORS.primaryLight,
};

export let defaultPhoto = {
    display: 'block',
    width: tileWidth,
    height: '166px',
};

export let loading = {
    padding: '30px',
    color: '#fff',
};

export let ListingsShowAll = styled.div`
    cursor: pointer;
    text-transform: uppercase;
    font-size: 10px;
    color: ${COLORS.grayFont};
    font-weight: 900;
    text-decoration: underline;
    position: absolute;
    right: 30px;
    bottom: 15px;
`;

export let ListingPhoto = styled.div`
    cursor: pointer;
    height: 166px;
    background-color: ${COLORS.primaryLight};
    display: inline-block;
    vertical-align: middle;
    // border-radius: 50%;
    overflow: hidden;
`;

/* copied from elsewhere */
export const encodePhotoSrc = (src) => {
    let url = '';
    if (src && typeof src === 'string') {
        url = src.match(/s/g) ? encodeURI(src) : src;
    }
    if (url && url.split(';').length > 2) {
        url = url.split(';').slice(1, url.split(';').length).join(';');
    }
    return url;
};

export let ListingImg = styled.img`
    background-size: cover;
    background-position: center;
    width: 310px;
    height: 166px;
    // background-image: ${(props) =>
        props.src ? 'url("' + encodePhotoSrc(props.src) + '")' : 'none'};
`;
