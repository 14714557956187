import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import Authorize from 'features/auth/components/Auth/Auth';
import VipListContainer from 'features/vip/containers/VipListContainer';
import VipPackageContainer from 'features/vip/containers/VipPackageContainer';
import ContentHeader from 'features/generic/components/ContentHeader/ContentHeader';
import DashboardListingsContainer from 'features/dashboard/containers/DashboardListingsContainer';
import {selectCurrentUser} from 'features/users/selectors';
import {getAccount} from 'features/accounts/actions';
import {withRouter} from '../../../../utils/helpers';

const GraphContainer = React.lazy(() =>
    import('features/dashboard/containers/GraphContainer'),
);

import * as styles from './styles';
import {
    VipContainer,
    VipPackage,
    Divider,
    TabContainer,
    TabsContainer,
    OuterContainer,
    ContentHeaderCss,
} from './styles';

/**
 * Hide line graph from internal users for RB Account
 */
let Dashboard = (props) => {
    useEffect(() => {
        // helpful to have account available in state (right after login) for authorization. Please don't remove. Will have global effects
        if (props.user.account_id) {
            props.getAccount(props.user.account_id);
        }
    }, []);

    return (
        <OuterContainer>
            <ContentHeader $css={ContentHeaderCss}>Dashboard</ContentHeader>
            <Authorize authorize={(user) => user.type === 'external'}>
                <GraphContainer {...props} />
            </Authorize>
            <DashboardListingsContainer {...props} />
            <Authorize permissions={['vipSubscription-view-all', 'vipPackage-view-all']}>
                <TabsContainer>
                    <TabContainer>VIP</TabContainer>
                    <Divider />
                </TabsContainer>
            </Authorize>
            <Authorize permissions={['vipSubscription-view-all', 'vipPackage-view-all']}>
                <VipContainer>
                    <VipListContainer />
                    <VipPackageContainer $css={VipPackage} />
                </VipContainer>
            </Authorize>
            {/* <KnowledgeBaseContainer /> */}
        </OuterContainer>
    );
};

export default withRouter(
    connect(
        (state, ownProps) => {
            return {
                user: selectCurrentUser(state),
            };
        },
        {
            getAccount,
        },
    )(Dashboard),
);
