import React from 'react';

import Modal from 'features/modal/components/Modal/Modal';
import * as styles from './styles';
import {
    Container,
    SelectDiv,
    SelectInner,
    OptionLabel,
    ArrowIcon,
    SelectedValue,
    OptionsCss,
    OptionDiv,
    ResetLink,
    SelectContainer,
} from './styles';

const Select = (props) => {
    const selected = props.options.includes(props.filters[props.id]);
    return (
        <SelectDiv>
            <SelectInner
                selected={selected}
                onClick={(e) => {
                    props.toggleModal(props.id);
                    e.stopPropagation();
                }}>
                <OptionLabel selected={selected}>{props.children}</OptionLabel>
                <SelectedValue selected={selected}>
                    {props.filters[props.id]}
                </SelectedValue>
                <ArrowIcon selected={selected} />
            </SelectInner>
            {props.id === props.activeModalId && (
                <Modal handleClose={() => props.toggleModal(null)} $css={OptionsCss}>
                    {props.options.map((option) => (
                        <OptionDiv
                            filters={props.filters}
                            key={option}
                            onClick={() => props.updateFilters(props.id, option)}>
                            {option}
                        </OptionDiv>
                    ))}
                </Modal>
            )}
        </SelectDiv>
    );
};

const ListFilters = (props) => {
    return (
        <Container>
            <SelectContainer>
                <Select {...props} id={'region_name'} options={props.regions}>
                    Region
                </Select>
                <Select
                    {...props}
                    id={'status'}
                    options={['active', 'auto-paused', 'user-canceled', 'offer']}>
                    Status
                </Select>
                <ResetLink onClick={props.resetFilters}>Reset Filters</ResetLink>
            </SelectContainer>
        </Container>
    );
};

export default ListFilters;
