import {darken, COLORS} from 'core/styles';
import styled, {css} from 'styled-components';

export function getLabelStyles(props) {
    const baseStyles = {
        fontSize: '13px',
        display: 'block',
        marginBottom: '10px',
        fontWeight: 500,
        color: COLORS.grayFont,
        position: 'relative',
    };

    if (props.required) {
        return Object.assign(
            baseStyles,
            {
                left: '10px',

                '&::before': {
                    content: '"*"',
                    position: 'absolute',
                    left: '-10px',
                    top: '-2px',
                    fontSize: '14px',
                    color: darken(COLORS.primary, 15),
                },
            },
            props.style,
        );
    }

    return Object.assign(baseStyles, props.style);
}

export const LabelStyledElem = styled.label`
    font-size: 13px;
    display: block;
    margin-bottom: 10px;
    font-weight: 500;
    color: ${COLORS.grayFont};
    position: relative;

    ${(props) =>
        props.required &&
        css`
            left: 10px;

            &::before {
                content: '*';
                position: absolute;
                left: -10px;
                top: -2px;
                font-size: 14px;
                color: ${darken(COLORS.primary, 15)};
            }
        `};
    ${(props) => (props.$css ? props.$css : '')};
`;
