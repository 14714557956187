import styled, {css} from 'styled-components';

import {COLORS, darken, lighten} from 'core/styles';
import TableArrow from 'assets/img/icons/table-arrow.svg';

export const Container = styled.div`
    min-weight: 25px;
    max-height: 25px;
    text-align: right;
    margin-bottom: 10px;
    opacity: ${(props) => (props.$isFetching ? 0.65 : 1)};
    ${(props) => (props.$css ? props.$css : '')}
`;

export const Showing = styled.div`
    vertical-align: middle;
    display: inline-block;
    color: ${lighten(COLORS.grayFont, 5)};
    font-weight: bold;
    margin: 0 10px;
    font-size: 12px;
`;

export const ArrowContainer = styled.div`
    vertical-align: middle;
    width: 25px;
    height: 25px;
    display: inline-block;
    background-color: #fff;
    text-align: center;
    padding-top: 4px;
    border-radius: 2px;
    border: 1px solid ${darken(COLORS.grayLight, 7)};
    opacity: ${(props) => (props.$isFetching ? 0.5 : 1)};
    &:active {
        background-color: ${COLORS.grayLight};
    }
`;

const ArrowSvgMixin = css`
    cursor: pointer;
    width: 100%;
    height: 100%;
    max-width: 15px;
    max-height: 15px;
`;

export const ArrowSvgLeft = styled(TableArrow)`
    ${ArrowSvgMixin}
    transform-origin: 50% 50%;
    transform: rotate(90deg);
`;

export const ArrowSvgRight = styled(TableArrow)`
    ${ArrowSvgMixin}
    transform-origin: 50% 50%;
    transform: rotate(-90deg);
`;

export const First = styled.div`
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    color: ${COLORS.primary};
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: underline;
    margin-left: 10px;
    font-size: 12px;
`;

export const Last = styled(First)`
    margin-left: 0px;
    margin-right: 10px;
    font-size: 12px;
`;
