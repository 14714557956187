import {post} from 'core/configureRequest';
import {get as getProp} from 'utils/helpers';
import {selectCurrentAccount} from 'features/accounts/selectors';
import {
    getToday,
    getYesterday,
    getNinetyDays,
    getThirtyDays,
    getFormatted,
} from 'utils/dates';
import {EVENT_CATEGORIES} from 'core/constants';
import {timeFormat, timeParse} from 'd3-time-format';
import {DASHBOARD_SPECIFIER as specifier} from 'core/constants';

export const requestAccountAnalytics = () => ({
    type: 'REQUEST_ACCOUNT_ANALYTICS',
});

export const receiveAccountAnalyticsFailure = (accountId) => ({
    type: 'RECEIVE_ACCOUNT_ANALYTICS_FAILURE',
    accountId,
});

export const receiveAccountAnalytics = (accountId, records) => ({
    type: 'RECEIVE_ACCOUNT_ANALYTICS',
    accountId,
    records,
});

export const requestListingAnalytics = () => ({
    type: 'REQUEST_LISTING_ANALYTICS',
});

export const receiveListingAnalyticsFailure = (accountId) => ({
    type: 'RECEIVE_LISTING_ANALYTICS_FAILURE',
    accountId,
});

export const receiveListingAnalytics = (accountId, records) => ({
    type: 'RECEIVE_LISTING_ANALYTICS',
    accountId,
    records,
});

export const updateEventType = (eventType) => ({
    type: 'UPDATE_DASHBOARD_EVENT_TYPE',
    eventType,
    meta: {
        analytics: {
            category: EVENT_CATEGORIES.Dashboard,
            label: eventType,
        },
    },
});

export const updateDateRange = (startDate, endDate) => ({
    type: 'UPDATE_DASHBOARD_DATE_RANGE',
    startDate,
    endDate,
    meta: {
        analytics: {
            category: EVENT_CATEGORIES.Dashboard,
            label: `${startDate} - ${endDate}`,
        },
    },
});

export const clickKnowledgeBase = () => ({
    type: 'KNOWLEDGE_BASE_CLICK',
    meta: {
        analytics: {
            category: EVENT_CATEGORIES.KnowledgeBase,
        },
    },
});

export const getAccountAnalytics = (accountId, callbackFunction) => (dispatch, getState) => {
    dispatch(requestAccountAnalytics());

    const state = getState();

    const startingDate = getProp(state, 'dashboard.activeStartingDate');
    const endingDate = getProp(state, 'dashboard.activeEndingDate');

    // Default analytics to 30 day period
    const defaultStarting = timeFormat(specifier)(
        new Date(new Date().setDate(new Date().getDate() - 30)),
    );
    const defaultEnding = timeFormat(specifier)(new Date());

    return post('/proxy/api/reporting/report/', {
        report_name: 'click-call-impression-by-day',
        filters: {
            account_id: {eq: accountId},
            created_at: {
                from: startingDate || defaultStarting,
                to: endingDate || defaultEnding,
            },
        },
    })
        .then((data) => {
            dispatch(receiveAccountAnalytics(accountId, data.data.records));
            callbackFunction && callbackFunction();

            return data;
        })
        .catch((e) => {
            dispatch(receiveAccountAnalyticsFailure(accountId));
            return Promise.reject(e);
        });
};

export const getAccountAnalyticsIfNeeded = (accountId) => (dispatch, getState) => {
    if (!accountId) return;

    const state = getState();

    if (state.dashboard.accountAnalyticsFetched.includes(accountId)) {
        return Promise.resolve(state.dashboard.accountAnalytics);
    }

    return dispatch(getAccountAnalytics(accountId));
};

export const getListingAnalytics = (accountId) => (dispatch, getState) => {
    dispatch(requestListingAnalytics());

    return post('/proxy/api/reporting/report/', {
        report_name: 'click-and-call-volume-by-listing',
        filters: {
            account_id: {eq: accountId},
            created_at: {
                from: getThirtyDays(),
                to: getToday(),
            },
        },
    })
        .then((data) => {
            dispatch(receiveListingAnalytics(accountId, data.data.records));

            return data;
        })
        .catch((e) => dispatch(receiveListingAnalyticsFailure(accountId)));
};

export const getListingAnalyticsIfNeeded = (accountId) => (dispatch, getState) => {
    const state = getState();

    if (state.dashboard.listingAnalyticsFetched.includes(accountId)) {
        return Promise.resolve(state.dashboard.listingAnalytics);
    }

    return dispatch(getListingAnalytics(accountId));
};
