import {COLORS, darken, MAX_WIDTH, bp} from 'core/styles';
import styled from 'styled-components';
import {css} from 'styled-components';

export let OuterContainer = styled.div`
    background-color: ${COLORS.grayLight};
`;

export let ContentHeaderCss = css`
    border-bottom: 1px solid ${darken(COLORS.grayLight, 5)};
`;

export let VipContainer = styled.div`
    display: flex;
    flex-flow: column-reverse wrap;
    padding: 30px;
    max-width: ${MAX_WIDTH};
    align-items: flex-start;
    ${bp('md')} {
        flex-flow: row wrap;
    }
`;

export let vipList = styled.div`
    flex: 2;
    margin-right: 0;
    padding: 0;

    ${bp('md')} {
        margin-right: 30px;
    }
`;

export let VipPackage = css`
    flex: 1;
    padding: 0;
    width: 100%;
    ${bp('md')} {
        width: auto;
    }
`;

export let TabsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 30px 30px 0;
    max-width: ${MAX_WIDTH};
`;

export let TabContainer = styled.div`
    padding: 10px 10px;
    color: ${COLORS.grayFont};
    font-weight: bold;
    margin-right: 15px;
    max-width: 60px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid ${darken(COLORS.grayLight, 15)};
`;

export let Divider = styled.div`
    flex: 1;
    height: 2px;
    background-color: ${darken(COLORS.grayLight, 15)};
`;
