import React from 'react';

import Pagination from 'features/generic/components/Pagination/Pagination';
import {isEmpty, get} from 'utils/helpers';
import LoadingStyled from '../../../generic/components/LoadingStyled/Loading';
import Star from 'assets/img/icons/review_star.svg';
import {TableCellStyled, TableRowStyled} from '../../../generic/components/Table/Table';
import {TileStyled} from 'features/generic/components/Tile/Tile';
import ListFilters from 'features/vip/components/ListFilters/ListFilters';

import columns from './columns';

import {
    VipListOuter,
    TileStyleCss,
    HeaderDiv,
    HeaderTitle,
    ListLoadingCss,
    ListError,
    EmptyList,
    ListRowCss,
    HeaderCellCss,
    HeaderRowCss,
} from './styles';

const VipList = (props) => {
    return (
        <VipListOuter>
            <TileStyled $css={TileStyleCss}>
                <HeaderDiv>
                    <div>
                        {/* <Star /> */}
                        <HeaderTitle>VIP State Subscriptions</HeaderTitle>
                    </div>
                    <div>
                        {props.isFetching && <LoadingStyled $css={ListLoadingCss} />}
                    </div>
                </HeaderDiv>
                <ListFilters {...props} />
                {!isEmpty(props.subscriptions) && (
                    <TableRowStyled $css={HeaderRowCss}>
                        {columns.map((column, i) => (
                            <TableCellStyled
                                key={i}
                                $css={HeaderCellCss}
                                flex={column.flex}>
                                <column.HeaderComponent />
                            </TableCellStyled>
                        ))}
                    </TableRowStyled>
                )}
                {props.error && <ListError>{props.error}</ListError>}
                {isEmpty(props.subscriptions) && !props.isFetching && (
                    <EmptyList>No Subscriptions Found</EmptyList>
                )}
                {props.subscriptions.map((subscription) => (
                    <TableRowStyled $css={ListRowCss} key={subscription.id}>
                        {columns.map((column, i) => (
                            <TableCellStyled flex={column.flex} key={i}>
                                <column.ChildComponent subscription={subscription} />
                            </TableCellStyled>
                        ))}
                    </TableRowStyled>
                ))}
            </TileStyled>
            <Pagination
                isFetching={props.isFetching}
                total={get(props, 'pagination.total')}
                count={get(props, 'pagination.count')}
                page={get(props, 'pagination.page')}
                handleLoadPage={(page) => props.handleLoadPage(page)}
            />
        </VipListOuter>
    );
};

export default VipList;
