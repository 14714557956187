import React from 'react';
import {LabelStyledElem} from './styles';

const LabelStyled = (props) => (
    <LabelStyledElem
        required={props.required}
        $css={props.$css}
        className={props.className}
        htmlFor={props.htmlFor}>
        {props.children}
    </LabelStyledElem>
);

export default LabelStyled;
