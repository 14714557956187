import {COLORS, darken, bp, Z} from 'core/styles';
import styled from 'styled-components';
import {css} from 'styled-components';
import Arrow from 'assets/img/icons/table-arrow.svg';

export let Container = styled.div`
    background-color: ${COLORS.primaryLight};
`;

export let SelectDiv = styled.div`
    position: relative;
    flex: 1;
`;

export let SelectInner = styled.div`
    background-color: ${(props) =>
        props.selected ? COLORS.primary : COLORS.primaryLight};
    border-bottom: 1px solid ${darken(COLORS.grayLight, 5)};
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    flex: 1;
    flex-flow: row wrap;
    height: 60px;
    width: 100%;
    padding: 0 15px;
    opacity: 1;
    border-right: ${(props) =>
        props.selected
            ? `1px solid ${darken(COLORS.primary, 15)}`
            : `1px solid ${darken(COLORS.primaryLight, 10)}`};

    ${bp('lg')} {
        width: auto;
        border-bottom: none;
    }
`;

export let ArrowIcon = styled(Arrow)`
    position: absolute;
    right: 10px;
    max-width: 15px;
    max-height: 15px;
    & polygon {
        fill: ${(props) => (props.selected ? '#fff' : COLORS.primary)};
    }
`;

export let SelectContainer = styled.div`
    display: flex;
`;

export let OptionsCss = css`
    position: absolute;
    left: 00px;
    width: 100%;
    background-color: #fff;
    padding: 0px;
    z-index: ${Z.modal};
    top: 100%;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
`;

export let OptionDiv = styled.div`
    cursor: pointer;
    font-size: 11px;
    text-transform: uppercase;
    color: ${(props) =>
        props.filters[props.id] === props.key ? '#fff' : COLORS.grayFont};
    padding: 10px;
    font-weight: bold;
    background-color: ${(props) =>
        props.filters[props.id] === props.key ? COLORS.primary : 'transparent'};
    &:hover {
        background-color: ${(props) =>
            props.filters[props.id] === props.key ? COLORS.primary : COLORS.primaryLight};
    }
    &:active {
        background-color: ${(props) =>
            props.filters[props.id] === props.key ? COLORS.primary : COLORS.primaryLight};
    }
`;

export let OptionLabel = styled.div`
    color: ${(props) => (props.selected ? '#fff' : COLORS.grayFont)};
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
    position: ${(props) => (props.selected ? 'absolute' : 'static')};
    top: ${(props) => (props.selected ? '12px' : '0px')};
    opacity: ${(props) => (props.selected ? 0.7 : 1)};
`;

export let SelectedValue = styled.div`
    color: #fff;
    position: absolute;
    font-weight: bold;
    bottom: 15px;
`;

export let ResetLink = styled.div`
    cursor: pointer;
    font-size: 10px;
    font-weight: bold;
    color: ${COLORS.primary}
    text-decoration: underline;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding: 0 30px;
    opacity: ${(props) =>
        props.filters?.site || props.filters?.region_name || props.filters?.status
            ? 1
            : 0.5};
`;
