import React from 'react';
import Link from 'next/link';

import ListingStats from 'features/dashboard/components/ListingStats/ListingStats';
import Authorize from 'features/auth/components/Auth/Auth';
import Loading from 'features/generic/components/Loading/Loading';
import {isEmpty} from 'utils/helpers';
import Photo from 'features/generic/components/Photo/Photo';
import {TileStyled} from 'features/generic/components/Tile/Tile';
import {
    getListingName,
    getListingType,
    formatListingTypeDisplayName,
} from 'utils/helpers';
import Button from 'features/generic/components/Button/Button';
import DefaultListingImage from 'assets/img/listing.svg';

import * as styles from './styles.js';
import {
    InnerContainer,
    ListingContainer,
    ListingIndicator,
    ListingTop,
    ListingType,
    OuterContainer,
    ListingTileCss,
    ListingPhoto,
    ListingImg,
    encodePhotoSrc,
    ListingsShowAll,
    InnerAnalytics,
} from './styles.js';

const DashboardListings = (props) => {
    if (props.isFetching) {
        return (
            <OuterContainer>
                <InnerContainer>
                    <Loading style={styles.loading} message="Getting listings..." />
                </InnerContainer>
            </OuterContainer>
        );
    }

    if (!props.isFetching && isEmpty(props.listings)) return <span />;

    const listings = props.showAll ? props.listings : props.listings.slice(0, 3);

    return (
        <OuterContainer>
            <InnerContainer>
                {listings.map((listing) => {
                    return (
                        <ListingContainer key={listing.listing_id}>
                            <ListingTop>
                                <ListingType>
                                    <ListingIndicator listing={listing} />
                                    {formatListingTypeDisplayName(
                                        getListingType(listing),
                                    )}
                                </ListingType>
                            </ListingTop>
                            <TileStyled $css={ListingTileCss} user={props.user}>
                                {isEmpty(listing.photos) && (
                                    <Link
                                        href={`/listing/${listing.listing_id}/details`}
                                        style={styles.defaultPhoto}>
                                        <DefaultListingImage />
                                    </Link>
                                )}
                                {!isEmpty(listing.photos) && (
                                    <Link href={`/listing/${listing.listing_id}/details`}>
                                        <ListingPhoto>
                                            <ListingImg
                                                src={encodePhotoSrc(
                                                    listing.photos[0].file_name,
                                                )}
                                            />
                                        </ListingPhoto>
                                    </Link>
                                )}
                                <InnerAnalytics>
                                    <Link
                                        style={styles.name}
                                        href={`/listing/${listing.listing_id}/details`}>
                                        {getListingName(listing)}
                                    </Link>
                                    {props.userCanViewAnalytics(
                                        props.user,
                                        listing.account_id,
                                    ) && <ListingStats listing={listing} />}
                                </InnerAnalytics>
                            </TileStyled>
                        </ListingContainer>
                    );
                })}
                {props.listings.length > 3 && (
                    <ListingsShowAll onClick={props.toggleShowAll}>
                        {props.showAll ? 'Hide' : 'Show All'}
                    </ListingsShowAll>
                )}
            </InnerContainer>
        </OuterContainer>
    );
};

export default DashboardListings;
