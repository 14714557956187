import React from 'react';

import * as styles from './styles';

const ListingStats = ({listing}) => {
    return (
        <div>
            <div style={styles.title}>30 Day Summary</div>
            <div style={styles.inner}>
                <div style={styles.group}>
                    <div style={styles.count}>
                        {typeof listing.clicks === 'number' ? (
                            listing.clicks
                        ) : (
                            <div style={styles.na}>N/A</div>
                        )}
                    </div>
                    <div style={styles.event}>Clicks</div>
                </div>
                <div style={styles.group}>
                    <div style={styles.count}>
                        {typeof listing.calls === 'number' ? (
                            listing.calls
                        ) : (
                            <div style={styles.na}>N/A</div>
                        )}
                    </div>
                    <div style={styles.event}>Calls</div>
                </div>
            </div>
        </div>
    );
};

export default ListingStats;
