import {COLORS, lighten} from 'core/styles';

export let title = {
    fontWeight: 'bold',
    fontSize: '11px',
    color: COLORS.grayFont,
    textTransform: 'uppercase',
    opacity: 0.5,
};

export let inner = {
    padding: '10px 0',
};

export let group = {
    display: 'inline-block',
    marginRight: '20px',
};

export let na = {
    color: COLORS.grayFont,
    opacity: 0.5,
};

export let count = {
    fontweight: 900,
    fontSize: '15px',
};

export let event = {
    textTransform: 'uppercase',
    color: lighten(COLORS.grayFont, 5),
    fontWeight: 500,
    fontSize: '10px',
};
