import {COLORS} from 'core/styles';
import styled from 'styled-components';

export let error = {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    fontSize: '11px',
    color: COLORS.danger,
    marginBottom: '10px',
    maxWidth: '500px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};

export const ErrorStyled = styled.div`
    white-space: nowrap;
    font-weight: bold;
    font-size: 11px;
    color: ${COLORS.danger};
    margin-bottom: 10px;
    max-width: 500px;
    over-flow: hidden;
    text-overflow: ellipsis;
`;
