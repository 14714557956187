import React from 'react';

import * as styles from './styles';
import {ContainerStyled} from './styles';

export const TileStyled = (props) => {
    return (
        <ContainerStyled
            {...props}
            $css={props.$css}
            className={props.className}
            onClick={props.handleClick}
            onKeyDown={props.handleKeyDown}>
            {props.children}
        </ContainerStyled>
    );
};

const Tile = (props) => {
    return (
        <div
            style={styles.container(props)}
            onClick={props.handleClick}
            onKeyDown={props.handleKeyDown}>
            {props.children}
        </div>
    );
};

export default Tile;
